import React from "react";
import CMOButton from "../../components/Button/Button";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import "./productCategories.scss";

class ProductCategories extends React.Component {
  resolveCategory(categories, categoryEnum) {
    return categories.find(
      (category) => category.categoryEnum === categoryEnum
    );
  }

  render() {
    const { PrimaryColor, SecondaryColor, Seo } = this.props.pageContext.page;

    const { categories } = this.props.pageContext;
    const location_href = this.props.location.pathname;
    return (
      <LayoutHeaderFooter
        header_background_color={PrimaryColor}
        header_secondary_color={SecondaryColor}
        header_no_fade={true}
        SEO_title={Seo.title}
        SEO_description={Seo.description}
        SEO_keywords={Seo.keywords}
        SEO_location={location_href}
        SEO_image={Seo.image}
        main_class={"product-categories-page"}
      >
        <section className="epenztargep blue-based">
          <img
            className="desktop-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "epenztargep").image.url
            }
            alt={this.resolveCategory(categories, "epenztargep").imageAlt}
            loading="lazy"
          />
          <img
            className="mobile-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "epenztargep").imageMobile.url
            }
            alt={this.resolveCategory(categories, "epenztargep").imageMobileAlt}
            loading="lazy"
          />
          <div className="left-section">
            <h2>{this.resolveCategory(categories, "epenztargep").title}</h2>
            <span className="description">
              {this.resolveCategory(categories, "epenztargep").description}
            </span>
            <a href={this.resolveCategory(categories, "epenztargep").slug}>
              <CMOButton
                variant="contained"
                background_color={"#5107FF"}
                text={this.resolveCategory(categories, "epenztargep").buttonText}
                color={"white"}
                onClick={() => { }}
              ></CMOButton>
            </a>
          </div>
        </section>

        <section className="penztargepek grey-based">
          <h2>Pénztárgép</h2>
          <div className="sections">
            <div className="left-section">
              <h3>{this.resolveCategory(categories, "ptgberles").title}</h3>
              <span className="description">
                {this.resolveCategory(categories, "ptgberles").description}
              </span>
              <a href={this.resolveCategory(categories, "ptgberles").slug}>
                <CMOButton
                  variant="contained"
                  background_color={"#5107FF"}
                  text={
                    this.resolveCategory(categories, "ptgberles").buttonText
                  }
                  color={"white"}
                  onClick={() => { }}
                ></CMOButton>
              </a>
            </div>
            <div className="separator"></div>
            <div className="right-section">
              <h3>{this.resolveCategory(categories, "ptgvasarlas").title}</h3>
              <span className="description">
                {this.resolveCategory(categories, "ptgvasarlas").description}
              </span>
              <a href={this.resolveCategory(categories, "ptgvasarlas").slug}>
                <CMOButton
                  variant="contained"
                  background_color={"#5107FF"}
                  text={
                    this.resolveCategory(categories, "ptgvasarlas").buttonText
                  }
                  color={"white"}
                  onClick={() => { }}
                ></CMOButton>
              </a>
            </div>
          </div>
        </section>

        <section className="pos white-based">
          <div className="left-section">
            <h2>{this.resolveCategory(categories, "pos").title}</h2>
            <span className="description">
              {this.resolveCategory(categories, "pos").description}
            </span>
            <a href={this.resolveCategory(categories, "pos").slug}>
              <CMOButton
                variant="contained"
                background_color={"#5107FF"}
                text={this.resolveCategory(categories, "pos").buttonText}
                color={"white"}
                onClick={() => { }}
              ></CMOButton>
            </a>
          </div>
          <img
            className="desktop-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "pos").image.url
            }
            alt={this.resolveCategory(categories, "pos").imageAlt}
            loading="lazy"
          />
          <img
            className="mobile-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "pos").imageMobile.url
            }
            alt={this.resolveCategory(categories, "pos").imageMobileAlt}
            loading="lazy"
          />
        </section>

        <section className="imin white-based">
          <div className="left-section">
            <h2>{this.resolveCategory(categories, "imin").title}</h2>
            <span className="description">
              {this.resolveCategory(categories, "imin").description}
            </span>
            <a href={this.resolveCategory(categories, "imin").slug}>
              <CMOButton
                variant="contained"
                background_color={"#5107FF"}
                text={this.resolveCategory(categories, "imin").buttonText}
                color={"white"}
                onClick={() => { }}
              ></CMOButton>
            </a>
          </div>
          <img
            className="desktop-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "imin").image.url
            }
            alt={this.resolveCategory(categories, "imin").imageAlt}
            loading="lazy"
          />
          <img
            className="mobile-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "imin").imageMobile.url
            }
            alt={this.resolveCategory(categories, "imin").imageMobileAlt}
            loading="lazy"
          />
        </section>

        {/* <section className="qr blue-based">
          <div className="left-section">
            <h2>{this.resolveCategory(categories, "qr").title}</h2>
            <span className="description">
              {this.resolveCategory(categories, "qr").description}
            </span>
            <a href={this.resolveCategory(categories, "qr").slug}>
              <CMOButton
                variant="contained"
                background_color={"white"}
                text={this.resolveCategory(categories, "qr").buttonText}
                color={"#5107FF"}
                onClick={() => { }}
              ></CMOButton>
            </a>
          </div>
          <img
            className="desktop-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "qr").image.url
            }
            alt={this.resolveCategory(categories, "qr").imageAlt}
          />
          <img
            className="mobile-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "qr").imageMobile.url
            }
            alt={this.resolveCategory(categories, "qr").imageMobileAlt}
          />
        </section> */}

        <section className="szamlazas white-based">
          <div className="left-section">
            <h2>{this.resolveCategory(categories, "szamlazas").title}</h2>
            <span className="description">
              {this.resolveCategory(categories, "szamlazas").description}
            </span>
            <a
              href={
                /*this.resolveCategory(categories, "szamlazas").slug*/ "/online-szamlazo"
              }
            >
              <CMOButton
                variant="contained"
                background_color={"#5107FF"}
                text={this.resolveCategory(categories, "szamlazas").buttonText}
                color={"white"}
                onClick={() => { }}
              ></CMOButton>
            </a>
          </div>
          <img
            className="desktop-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "szamlazas").image.url
            }
            alt={this.resolveCategory(categories, "szamlazas").imageAlt}
            loading="lazy"
          />
          <img
            className="mobile-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "szamlazas").imageMobile.url
            }
            alt={this.resolveCategory(categories, "szamlazas").imageMobileAlt}
            loading="lazy"
          />
        </section>

        <section className="nyomtatok grey-based">
          <div className="left-section">
            <h2>{this.resolveCategory(categories, "nyomtato").title}</h2>
            <span className="description">
              {this.resolveCategory(categories, "nyomtato").description}
            </span>
            <a href={this.resolveCategory(categories, "nyomtato").slug}>
              <CMOButton
                variant="contained"
                background_color={"#5107FF"}
                text={this.resolveCategory(categories, "nyomtato").buttonText}
                color={"white"}
                onClick={() => { }}
              ></CMOButton>
            </a>
          </div>
          <img
            className="desktop-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "nyomtato").image.url
            }
            alt={this.resolveCategory(categories, "nyomtato").imageAlt}
            loading="lazy"
          />
          <img
            className="mobile-img"
            src={
              process.env.GATSBY_CMS_URL +
              this.resolveCategory(categories, "nyomtato").imageMobile.url
            }
            alt={this.resolveCategory(categories, "nyomtato").imageMobileAlt}
            loading="lazy"
          />
        </section>
      </LayoutHeaderFooter>
    );
  }
}

export default ProductCategories;
